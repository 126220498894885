/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 36px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: white;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #181818;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    width: 48px;
    height: 48px;
    right: 36px;
    top: 36px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: white;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: rgba(24,24,24, 0.8);
    padding: 2.4em 2.5em 0;
    font-size: 1.8em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #ffd700;
  }