.nav-bar {
    background: #181818;
    width: 80px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:space-between;
    padding: 24px 0;

    @media screen and (max-width: 720px){
      position: relative;
      width: 100%;
      height: 100px;
      flex-direction: row;
    }

    .desktop-navigation{
      display: block;

    @media screen and (max-width: 720px){
      display: none;
      }
    }

    .mobile-navigation{
      display:none;

    @media screen and (max-width: 720px){
      display:block;
    }
  }

    button {
      left: 40px;
      top: 40px;
      z-index: 10;
      cursor: pointer;
    }
  
    .logo {
      display: block;
      width: 100%;
      padding: 0;
      @media screen and (max-width: 720px){
        width: 25%;
        height: 100%
      }
  
      img {
        display: block;
        margin: 8px auto;
        width: 36px;
        height: auto;
        transform: scale(2);

        @media screen and (max-width: 720px){
          margin: auto 24px;
          transform: scale(1.8)
        }
  
        &.sub-logo {
          width: 50px;
        }
      }

      
    }
  
    nav {
      display: block;
      text-align: center;
      width: 100%;
  
      a {
        font-size: 24px;
        color: #4d4d4e;
        display: block;
        line-height: 51px;
        height: 51px;
        position: relative;
        text-decoration: none;
  
        i {
          transition: all 0.3s ease-out;
        }
  
        &:hover {
          color: #ffd700;
  
          svg {
            opacity: 0;
          }
  
          &:after {
            opacity: 1;
          }
        }
  
        &:after {
          content: '';
          font-size: 12px;
          letter-spacing: 2px;
          position: absolute;
          bottom: 0;
          display: block;
          width: 100%;
          text-align: center;
          opacity: 0;
          -webkit-transition: all 0.3s ease-out;
          transition: all 0.2s ease-out;
        }
  
        &:first-child {
          &:after {
            content: 'HOME';
          }
        }
      }
  
      a.about-link {
        &:after {
          content: 'ABOUT';
        }
      }
  
      a.contact-link {
        &:after {
          content: 'CONTACT';
        }
      }
  
      a.portfolio-link {
        &:after {
          content: 'PORTFOLIO';
        }
      }
  
      a.active {
        svg {
          color: #ffd700;
        }
      }
    }
  
    ul {
      width: 100%;
      display: block;
      padding: 0;
      list-style: none;
      text-align: center;
      margin: 0;
  
      li {
        a {
          padding: 7px 0;
          display: block;
          font-size: 24px;
          line-height: 16px;
          color: #4d4d4e;
  
          &:hover {
            color: #ffd700;
          }
        }
      }
    }
  }