.page {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
.top-tags {
    bottom: auto;
    top: 35px;

    @media screen and (max-width: 720px){
      top: 5px;
    }
  }

.bottom-tags {
  @media screen and (max-width: 720px){
    top: 75%;
  }
}
  
.tags {
    color: #ffd700;
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    left: 120px;
    font-size: 18px;
    font-family: 'La Belle Aurore', cursive;

    @media screen and (max-width: 920px){
      left: 95px;
    }
    @media screen and (max-width: 720px){
      left: 10px;
    }
  }
  
.bottom-tag-html {
    margin-left: -5px;
  }
  
.container {
    width: 100%;
    will-change: contents;
    height: 80%;
    min-height: 566px;
    position: absolute;
    opacity: 0;
    top: 5%;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;

    @media screen and (max-width: 720px){
      top: 0;
    }
  }

.container.contact-page,
.container.about-page,
.container.portfolio-page {
  
    h1 {
      font-size: 53px;
      font-family: 'Coolvetica';
      color: #ffd700;
      font-weight: 400;
      letter-spacing: 2px;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: -10px;
      @media screen and (max-width: 720px){
        font-size: 43px;
        margin-bottom: 25px;
      }
      @media screen and (max-width: 320px){
        font-size: 33px;
      }

      &:before {
        content: '<h1>';
        font-family: 'La Belle Aurore', cursive;
        color: #ffd700;
        font-size: 18px;
        position: absolute;
        margin-top: -10px;
        left: -10px;
        opacity: 0.6;
        line-height: 18px;
      }

      &:after {
        content: '<h1/>';
        font-family: 'La Belle Aurore', cursive;
        color: #ffd700;
        font-size: 18px;
        line-height: 18px;
        position: absolute;
        left: -30px;
        bottom: -20px;
        margin-left: 20px;
        opacity: 0.6;
      }
    }

    p {
      font-size: 13px;
      color: #fff;
      font-family: sans-serif;
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;
      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }
      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
      &:nth-of-type(4) {
        animation-delay: 1.4s;
      }
    }

  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;
    @media screen and (max-width: 912px){
      margin-left: 50px;
      width: 80%;
    }
    @media screen and (max-width: 720px){
      margin-left: 0;
      width: 80%;
    }
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
}
  
 