.stage-cube-cont {
    width: 50%;
    height: 100%;
    top: 0;
    padding-top: 18%;
    margin-left: 0;
    position: absolute;
    right: 0;
    overflow: hidden;
    @media screen and (max-width: 720px){
      width: 50%;
      height: 50%;
      top: 500px;
    }
    @media screen and (max-width: 380px){
      top: -20px;
    }
  }
  
  .cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);
    @media screen and (max-width: 720px){
      transform-origin: 25px 25px 0;
      margin-left: calc(50% - 25px);
    }
  
    div {
      position: absolute;
      width: 200px;
      height: 200px;
      border: 1px solid #ccc;
      background: rgba(255, 255, 255, 0.4);
      text-align: center;
      font-size: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 20px 0px lightyellow;
      @media screen and (max-width: 720px){
        width: 50px;
        height: 50px;
        font-size: 25px;
      }
    }
  
    .face1 {
      transform: translateZ(100px);
      @media screen and (max-width: 720px){
        transform: translateZ(25px);
      }
    }
    .face2 {
      transform: rotateY(90deg) translateZ(100px);
      @media screen and (max-width: 720px){
        transform: rotateY(90deg) translateZ(25px);
      }
    }
    .face3 {
      transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
      @media screen and (max-width: 720px){
        transform: rotateY(90deg) rotateX(90deg) translateZ(25px);
      }
    }
    .face4 {
      transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
      @media screen and (max-width: 720px){
        transform: rotateY(180deg) rotateZ(90deg) translateZ(25px)
      }
    }
    .face5 {
      transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
      @media screen and (max-width: 720px){
        transform: rotateY(-90deg) rotateZ(90deg) translateZ(25px);
      }
    }
    .face6 {
      transform: rotateX(-90deg) translateZ(100px);
      @media screen and (max-width: 720px){
        transform: rotateX(-90deg) translateZ(25px);
      }
    }
  }
  
  @keyframes spincube {
    from,
    to {
      transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    16% {
      transform: rotateY(-90deg);
    }
    33% {
      transform: rotateY(-90deg) rotateZ(90deg);
    }
    50% {
      transform: rotateY(-180deg) rotateZ(90deg);
    }
    66% {
      transform: rotateY(-270deg) rotateX(90deg);
    }
    83% {
      transform: rotateX(90deg);
    }
  }